html ,body {
  margin: 0;
  font-family: "Poppins";

  padding: 0;

width: 100%;
height: 100%;
 

  overflow-x: hidden;
  width: 100%;
  height: 100%;

}
