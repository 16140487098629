.ToolScheduling-main-container{
    margin-top: 100px;
    margin-bottom: 100px;
}

.ToolScheduling-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

}
.ToolScheduling-desc {
    font-weight: 400;
    font-size: 20px;
    word-break: normal;
    width:732px;
}
.ToolScheduling-tool-upper-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.ToolScheduling-tool-inner-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: auto;
    height: 174px;
    margin:20px 10px;
}

.ToolScheduling-Badge {
    background-color: black;
    width: 44px;
    height: 44px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding: 6px 8px;
}

.ToolScheduling-tool-title {
    font-weight: 700;
    font-size: 26px;
    // line-height: 23px;
    margin-bottom: 0px !important;
}
.ToolScheduling-tool-desc {
    font-weight: 400;
    font-size: 20px;
    word-break: normal;
}
.size-adjustment{
    font-weight: bold;
}

@media  (min-width: 310px) and (max-width:374px){
    .ToolScheduling-desc {
        padding-left: 0px;
        width: auto;
        font-size: 14px!important;
        line-height: 20px !important;
       
    }

   
  
    .ToolScheduling-tool-upper-box {
        display: block;
        
    }
    .ToolScheduling-tool-inner-box {
      
        width: auto;
       
    }
    
    .ToolScheduling-desc {
        width: auto;
        margin-left: 50px;
        margin-right: 50px;
        font-size: 10px !important;
    }
  
    .ToolScheduling-tool-upper-box {
        display: flex;
        flex-direction: column;
        
    }
    
    .Component-titlee-tool-color {
        background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-size: 15px !important;
        font-weight: 600;
        padding-left: 20px;
        padding-right: 20px;
    }
    .ToolScheduling-main-container {
        margin-top: 130px;
    }
    .ToolScheduling-tool-title {
        font-size: 13px !important;
        font-weight: 600 !important;
    }
    .ToolScheduling-tool-desc {
        font-size: 9px;
        width: 220px !important;
    }
    .ToolScheduling-Badge {
        background-color: black;
        width: 30px;
        height: 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
    }
    .ToolScheduling-tool-inner-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: auto;
        height: 95px;
        margin: 20px 10px;
    }
    
   
   
}
















@media  (min-width: 375px) and (max-width:767px){
    .ToolScheduling-desc {
        padding-left: 0px;
        width: auto;
        font-size: 14px!important;
        line-height: 20px !important;
       
    }

   
  
    .ToolScheduling-tool-upper-box {
        display: block;
        
    }
    .ToolScheduling-tool-inner-box {
      
        width: auto;
       
    }
    
    .ToolScheduling-desc {
        width:auto;
        margin-left: 50px;
        margin-right: 50px;
        font-size: 12px !important;
    }

  
    .ToolScheduling-tool-upper-box {
        display: flex;
        flex-direction: column;
        
    }
    .ToolScheduling-tool-inner-box {
      
        width: auto;
       
    }
    .Component-titlee-tool-color{

        background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-size: 20px !important;
        font-weight: 600;
        padding-left: 20px;
        padding-right: 20px;
    }
    .ToolScheduling-main-container {
        margin-top: 130px;
    }
    .ToolScheduling-tool-title{
        font-size: 23px;
    }
    .ToolScheduling-tool-desc {
        
        font-size: 12px;
        width:350px !important;
        
    }
    
    .ToolScheduling-tool-title {
        font-size: 18px !important;
    }
   
}


@media  (min-width: 768px) and (max-width:1023px){
    .ToolScheduling-desc {
        padding-left: 0px;
        width: auto;
        font-size: 14px;
        line-height: 24px;
       
    }
    .ToolScheduling-tool-upper-box {
        display: block;
        
    }
    .ToolScheduling-tool-inner-box {
      
        width: auto;
       
    }
    
    .ToolScheduling-desc {
        width:auto;
        margin-left: 150px;
        margin-right: 150px;
        font-size: 18px;
    }
    .ToolScheduling-tool-upper-box {
        display: flex;
        flex-direction: column;
        
    }
    .ToolScheduling-tool-inner-box {
      
        width: auto;
       
    }
    .Component-titlee-tool-color{

        background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-size: 32px !important;
        font-weight: 600;
        padding-left: 20px;
        padding-right: 20px;
    }
    .ToolScheduling-main-container {
        margin-top: 130px;
    }
    .ToolScheduling-tool-title{
        font-size: 23px;
    }
    .ToolScheduling-tool-desc {
        
        font-size: 18px;
        width:350px !important;
        
    }
    
}

@media  (min-width: 1024px) and (max-width:1439px){
    .ToolScheduling-desc {
        padding-left: 0px;
        width: auto;
        font-size: 14px;
        line-height: 24px;
       
    }

    .ToolScheduling-tool-upper-box {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
    }
    
    .ToolScheduling-tool-inner-box {
      
        width: auto;
       
    }
    
    .ToolScheduling-desc {
        width:auto;
        margin-left: 150px;
        margin-right: 150px;
        font-size: 18px;
    }
    .ToolScheduling-tool-upper-box {
        display: flex;
        flex-direction: column;
        
    }
    .ToolScheduling-tool-inner-box {
      
        width: auto;
       
    }
    .Component-titlee-tool-color{

        background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-size: 37px !important;
        font-weight: 600;
        padding-left: 20px;
        padding-right: 20px;
    }
    .ToolScheduling-main-container {
        margin-top: 130px;
    }
    .ToolScheduling-tool-title{
        font-size: 20px;
    }
    .ToolScheduling-tool-desc {
        
        font-size: 19px;
        width:350px !important;
    }
    
}

@media (min-width:1440px) and (max-width:2559px){

    .Component-titlee-tool-color {
        background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-size: 50px !important;
        font-weight: 700 !important;
        line-height: 60px;
        margin-top: 90px;
    }
    .ToolScheduling-tool-title {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 0px !important;
    }
    .ToolScheduling-Badge {
        background-color: black;
        width: 36px;
        height: 36px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
   
}

@media (min-width:2560px) {

    .Component-titlee-tool-color {
        background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-size: 37px !important;
        font-weight: 700 !important;
        line-height: 60px;
        margin-top: 90px;
    }
    .ToolScheduling-tool-title {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 0px !important;
    }
    .ToolScheduling-Badge {
        background-color: black;
        width: 37px;
        height: 37px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
}





