.button {
  // width: 160px;
  height: 50px;
  background: linear-gradient(
    270deg,
    #ba9743 -75%,
    #ffd66b 7.11%,
    #ba9743 112.68%
  );
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
