   .mainbanner-main-container{
    background-image: url("../../assets/images/min-banner-bg.png");
    background-size: 100% 100%;
    height: 373px;
    display: flex;
    justify-content: center;
    align-items: center;
   }
   @media (min-width: 375px)and (max-width:767px)  {

      .mainbanner-main-container{
         background-image: url("../../assets/images/min-banner-bg.png");
         background-size: 100% 100%;
         height: 373px;
         display: flex;
         justify-content: center;
         align-items: center;
         text-align: center;
         overflow: none;
        } 
   }
  