#fourth-section {
  width: 100%;

  background-size: cover;
  position: relative;
}
#fourth-section .container {
  padding-top: 60px;
}

.header-button {
  margin-top: 15px;
}

@media (max-width: 992px) {
  #fourth-section .container {
    padding-top: 62px;
  }
}

#fourth-section h1 {
  width: 544px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

#fourth-section h2 {
  width: 575px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  color: #000000;
}

#fourth-section .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 50px;
  transition: 0.5s;
  margin-top: 30px;
  border: 2px solid #fff;
  color: #fff;
}

#fourth-section .btn-get-started:hover {
  background: #5fcf80;
  border: 2px solid #5fcf80;
}

@media (min-width: 310px) and (max-width: 374px) {
  #fourth-section {
    width: 100%;
    background-size: cover;
    position: relative;
    margin-top: 60px;
  }

  #fourth-section h1 {
    font-size: 20px;
    line-height: 26px;
    width: 175px;
    margin-left: 10px;
   
}

  #fourth-section h2 {
    width: 110px;
    font-size: 10px;
    line-height: 24px;
    margin-left: 10px;
    
    padding-left: 0px;
}

  #fourth-section img {
    height: 110px;
    margin-right: 10px;
  }

  #fourth-section .button {
    width: 105px;
    height: 25px;
    font-size: 12px;
    margin-left: 10px;
}
}


@media (min-width: 375px) and (max-width: 423px) {
  #fourth-section {
    width: 100%;
    background-size: cover;
    position: relative;
    margin-top: 60px;
  }

  #fourth-section h1 {
    font-size: 20px;
    line-height: 26px;
    width: 200px;
    margin-left: 20px;
    padding-left: 10px;
  }

  #fourth-section h2 {
    width: 180px;
    font-size: 12px;
    line-height: 24px;
    margin-left: 30px;
    padding-left: 0px;
  }

  #fourth-section img {
    height: 120px;
  }

  #fourth-section .button {
    width: 120px;
    height: 30px;
    font-size: 12px;
    margin-left: 30px;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  #fourth-section {
    width: 100%;
    background-size: cover;
    position: relative;
    margin-top: 60px;
  }

  #fourth-section h1 {
    font-size: 22px;
    line-height: 26px;
    width: 200px;
    margin-left: 0px;
    padding-left: 10px;
  }

  #fourth-section h2 {
    width: 180px;
    font-size: 12px;
    line-height: 24px;
    margin-left: 10px;
    padding-left: 0px;
  }

  #fourth-section img {
    height: 150px;
  }

  #fourth-section .button {
    width: 120px;
    height: 30px;
    font-size: 12px;
    margin-left: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  #fourth-section {
    margin-top: 80px;
  }
  #fourth-section h1 {
    font-size: 32px;
    line-height: 36px;
    width: 60vw;
    margin-right: 10px;
  }

  #fourth-section h2 {
    font-size: 18px;
    line-height: 24px;
    width: 250px;
  }

  #fourth-section img {
    height: 210px;
  }
  #fourth-section .button {
    width: 170px;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  #fourth-section {
    margin-top: 150px;
  }

  #fourth-section h1 {
    font-size: 37px;
    line-height: 40px;
  }

  #fourth-section h2 {
    font-size: 18px;
    line-height: 26px;
  }
  #fourth-section img {
    height: 300px;
  }
  #fourth-section {
    background-attachment: fixed;
  }
  #fourth-section .button {
    width: 170px;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  #fourth-section {
    margin-top: 100px;
  }

  #fourth-section h1 {
    font-size: 50px;
    line-height: 60px;
    width: 640px;
    font-weight: 700;
  }

  #fourth-section h2 {
    font-size: 18px;
    line-height: 26px;
    width: 575px;
  }
  #fourth-section img {
    height: 435px;
    padding-top: 40px;
  }
  #fourth-section {
    background-attachment: fixed;
  }
  #fourth-section .button {
    width: 170px;
  }
}

@media (min-width:2560px)
{
  #fourth-section h1 {
    width: 544px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 37px !important;
    line-height: 60px;
    background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
#fourth-section img {
  height: 435px;
  padding-top: 40px;
}
.button {
  width: 180px;
}
}
