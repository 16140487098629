.price-plan-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  text-align: center;
}
.price-plan-subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
}

.price-plan-desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}

.price-plan-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.price-plan-list-container {
  width: 100%;
  padding: 40px 30px;
  background: #000000;
  border-radius: 25px;
}

.price-plans-price-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: center;
}

.price-plans-listing-container > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  // color: aqua;
}

.price-plans-listing-container > ul {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-left: 20px;
  color: white;
  // color: aquamarine;
}

.price-plans-listing-container > ul > li {
  border-bottom: 1px solid #ffffff;
  padding-bottom: 10px;
  margin-top: 10px;
  // color: aquamarine;
  width: 100%;
}

.price-plans-card-checkbox {
  // border-bottom: 1px solid white;
  padding-bottom: 20px;
  margin-top: 10px;
}

.price-plans-checkbox-container {
  width: 25%;
  margin-left: 10px;
  margin-right: 10px;
  height: 1400px;
  background: rgba(0, 0, 0, 0.78);
  box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
  -webkit-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
  -moz-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
}

.price-plans-value {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
}

.price-plans-card-desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: white;
}

.price-plans-card-button {
  width: 159px;
  height: 61px;
  border: 1px solid #ba9743;
  background: none;
  border-radius: 10px;
  color: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
@media only screen and (min-width: 320px) and (max-width: 374px) {
  .price-plans-listing-container > ul {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 20px;
    color: white;
    // color: aquamarine;
  }

  //   .show{display: none}
  .price-plan-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
  }
  #ss {
    display: none;
  }
  .price-plan-subtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
}
  .price-plan-list-container {
    width: 100%;
    padding: 40px 30px;
    background: #000000;
    border-radius: 25px;
    // display: block;
    margin-top: 10px;
  }
  .price-plans-checkbox-container {
    width: 100%;
    margin-left: 2px;
    margin-right: 2px;
    height: 1900px;
    background: rgba(0, 0, 0, 0.78);
    box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    -webkit-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    -moz-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    // justify-content:space-between;
    align-items: center;
  }
  .price-plans-card-checkbox {
    // border-bottom: 1px solid white;
    padding-bottom: 20px;
    padding-top: 1px;
    // margin-top: 29px;
    //justify-content:space-between;
  }
  .price-plans-value {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }
  .price-plans-card-desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    color: rgb(255, 251, 251);
    // margin-top: 20px;
  }
  .price-plans-card-button {
    width: 90%;
    height: 80px;
    border: 1px solid #ba9743;
    background: none;
    border-radius: 10px;
    color: #fff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    font-size: 15px;
    margin-bottom: 100px;
    // margin-top: 20px;
  }
  .dollar-sign {
    font-size: 15px;
    font-weight: 500px;
  }
  .price-plans-card-desc1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: rgb(255, 251, 251);
    // margin-top: 20px;
  }
  .PricePlans-card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #030303;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}
.PricePlans-card-ul > li {
  margin-top: 0px;
  width: 200px;
  font-size: 9px;
  list-style: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAhOAAAITgGMMQDsAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMJSURBVHgBvVRNaFNBEJ7Zty/9tUmhBz3YRs+CyUE8lNL0UOpBaNpTi0gTPIinevVUexCkIujBm7SKYE/yIohCEUz1IOghwYpFBNuiN9EEW9vYvOy4u2/fT9qcHUjevnkz38583+witDBnKZPo2ec5RBgnghQBJJSfMSwTQlnU6eHolZfFVrl40PFmaWxWAswBYS9pD+kw1CtSTzKhq1ablRu88HyrJWBJVlXjXY6MHyby/ObPAKB+JaJoftVCyp+9+KLgO5i/EB3xJZuzYc4tjNkW2ObHLQuVj3MG6hd8k2v5TDDOnffL5zNNFa49yc41BMzJF4zuj16fXtOqVwqrVl1giLIptiGdzheqfN3JJolZOUZhrEoPmRMyGRGZ17oGCsC9/qUrCQm8KpfXuRWzM6gcHj2oi0EVyDT/RAwNe6CAvcpV2ZpcU6RKxFkNKDmZkYGaewrUPNwZGl/85CTEepL4o3ybIKAE1abxbyvTUgPOU0EfxiJCYoRP6k5OYtexIfhZvkEyDyHKLaoOIC0rZHFVPoRtgNXWB50DWfjz9TEId1ejdhwfx86jQ1BZuwlYr6BSWlHjgXnQsr+4nAhuOINgbJEqZEnqek9fg51PC9DePwW8ux921heANSrAuA+GkTnXkIi/313akEdqQFaHmsWQa2xL5sCKS0bqv2Dv8y0Ad8+cmxAGMRBTQogJzm3+lGmFyCdE86+W4vsjgO0PIHa+AId9ANVmOCqIRkkjHcVErMQ72pkjGlpyo4IX5e8Ou2tqgBBsBhERmubc5K3iqXtb2lErXX4lxzeD5rsCZLqVJo4C+iOzYPZHdLExciR9v8jVp73a37zrUsm/pkJ6IlyF1fg0+scUpQZ3+wYfFE2cZxsrUzkQsEgh54bQiEr+9EdQpbN8Ymw5HbYesY9ONiUEOuooehcCGQXDWgMhQF+4d+og5tMThWpLQGVvl88lmctzAsWMTBzwRwL02BsiEYvyoMyfmX5WPJh/CDBqrxdHhxsupNwG9aqbU876JrPrhZF8sQr/y/4B85BHgIMFrgsAAAAASUVORK5CYII=);
}
.PricePlans-button {
  width: 220px;
  height: 35px;
  border: none;
  border-radius: 38px;
  border: 1px solid #ba9743;
  background: none;
}
.form-check-label {
  margin-bottom: 0;
  font-size: 12px;
}
.price-plan-desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}

}
@media only screen and (min-width: 375px) and (max-width: 424px) {
  .price-plans-listing-container > ul {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 20px;
    color: white;
    // color: aquamarine;
  }

  //   .show{display: none}
  .price-plan-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
  }
  #ss {
    display: none;
  }

  .price-plan-list-container {
    width: 100%;
    padding: 40px 30px;
    background: #000000;
    border-radius: 25px;
    // display: block;
    margin-top: 10px;
  }
  .price-plans-checkbox-container {
    width: 100%;
    margin-left: 2px;
    margin-right: 2px;
    height: 1900px;
    background: rgba(0, 0, 0, 0.78);
    box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    -webkit-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    -moz-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    // justify-content:space-between;
    align-items: center;
  }
  .price-plans-card-checkbox {
    // border-bottom: 1px solid white;
    padding-bottom: 20px;
    padding-top: 1px;
    // margin-top: 29px;
    //justify-content:space-between;
  }
  .price-plans-value {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }
  .price-plans-card-desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    color: rgb(255, 251, 251);
    // margin-top: 20px;
  }
  .price-plans-card-button {
    width: 90%;
    height: 80px;
    border: 1px solid #ba9743;
    background: none;
    border-radius: 10px;
    color: #fff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    font-size: 15px;
    margin-bottom: 100px;
    // margin-top: 20px;
  }
  .dollar-sign {
    font-size: 15px;
    font-weight: 500px;
  }
  .price-plans-card-desc1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: rgb(255, 251, 251);
    // margin-top: 20px;
  }
}
@media only screen and (min-width: 425px) and (max-width: 767px) {
  .price-plan-subtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }
  .price-plans-listing-container > ul {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 20px;
    color: white;
    // color: aquamarine;
  }

  .price-plan-desc {
    font-size: 13px;
  }

  //   .show{display: none}
  .price-plan-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    text-align: center;
  }
  #ss {
    display: none;
  }

  .price-plan-list-container {
    width: 100%;
    padding: 40px 30px;
    background: #000000;
    border-radius: 25px;
    // display: block;
    margin-top: 10px;
  }
  .price-plans-checkbox-container {
    width: 100%;
    margin-left: 2px;
    margin-right: 2px;
    height: 1750px;
    background: rgba(0, 0, 0, 0.78);
    box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    -webkit-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    -moz-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    // justify-content:space-between;
    align-items: center;
  }
  .price-plans-card-checkbox {
    // border-bottom: 1px solid white;
    padding-bottom: 21px;
    margin-top: 0px;
    padding-top: 10px;
    //justify-content:space-between;
  }
  .price-plans-value {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
  }
  .price-plans-card-desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: rgb(255, 251, 251);
    // margin-top: 20px;
  }
  .price-plans-card-button {
    width: 90%;
    height: 61px;
    border: 1px solid #ba9743;
    background: none;
    border-radius: 10px;
    color: #fff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    font-size: 15px;
    margin-bottom: 50px;
    // margin-top: 20px;
  }
  .dollar-sign {
    font-size: 15px;
    font-weight: 500px;
  }
  .price-plans-card-desc1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: rgb(255, 251, 251);
    // margin-top: 20px;
  }
}

// for 768 screen size

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  #dd {
    display: none;
  }

  .price-plan-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
  }

  .price-plans-checkbox-container {
    width: 35%;
    margin-left: 10px;
    margin-right: 10px;
    height: 1750px;
    background: rgba(0, 0, 0, 0.78);
    box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    -webkit-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    -moz-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    // margin-bottom: 0px;
  }
  .price-plans-card-checkbox {
    // border-bottom: 1px solid white;
    padding-bottom: 20px;
    margin-top: 20px;
    padding-top: 11px;
    //justify-content:space-between;
  }

  .price-plans-card-button {
    width: 85px;
    height: 70px;
    border: 1px solid #ba9743;
    background: none;
    border-radius: 10px;
    color: #fff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .dollar-sign {
    font-size: 20px;
    font-weight: 500px;
  }
  .price-plans-card-desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: rgb(255, 251, 251);
  }
  .price-plans-card-desc1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: rgb(255, 251, 251);
    padding-top: 20px;
  }
  #hide {
    display: none;
  }
  .price-plans-value {
    font-size: 30px;
  }
  .price-plan-desc{
    font-size: 18px;
  }
}


@media only screen and (min-width: 1024px) and (max-width: 2560px) {
  #dd {
    display: none;
  }

  .price-plan-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 37px !important;
    text-align: center;
}

  .price-plans-checkbox-container {
    width: 30%;
    margin-left: 10px;
    margin-right: 10px;
    height: 1450px;
    background: rgba(0, 0, 0, 0.78);
    box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    -webkit-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    -moz-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    // margin-bottom: 0px;
  }
  .price-plans-card-checkbox {
    // border-bottom: 1px solid white;
    padding-bottom: 20px;
    margin-top: 15px;
    padding-top: 1px;
    //justify-content:space-between;
  }

  .price-plans-card-button {
    width: 185px;
    height: 75px;
    border: 1px solid #ba9743;
    background: none;
    border-radius: 10px;
    color: #fff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0px;
  }
  .dollar-sign {
    font-size: 20px;
    font-weight: 500px;
  }
  .price-plans-card-desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: rgb(255, 251, 251);
  }
  .price-plans-card-desc1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: rgb(255, 251, 251);
    padding-top: 20px;
  }
  #hide {
    display: none;
  }
  .price-plans-value {
    font-size: 35px;
  }
}


@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  #dd {
    display: none;
  }

  .price-plans-checkbox-container {
    width: 30%;
    margin-left: 10px;
    margin-right: 10px;
    height: 1530px;
    background: rgba(0, 0, 0, 0.78);
    box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    -webkit-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    -moz-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    // margin-bottom: 0px;
  }
  .price-plans-card-checkbox {
    // border-bottom: 1px solid white;
    padding-bottom: 20px;
    margin-top: 20px;
    padding-top: 1px;
    //justify-content:space-between;
  }

  .price-plans-card-button {
    width: 140px;
    height: 75px;
    border: 1px solid #ba9743;
    background: none;
    border-radius: 10px;
    color: #fff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .dollar-sign {
    font-size: 20px;
    font-weight: 500px;
  }
  .price-plans-card-desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    color: rgb(255, 251, 251);
  }
  .price-plans-card-desc1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: rgb(255, 251, 251);
    padding-top: 20px;
  }
  #hide {
    display: none;
  }
  .price-plans-value {
    font-size: 30px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 2560px) {
  #dd {
    display: none;
  }

  .price-plans-checkbox-container {
    width: 30%;
    margin-left: 10px;
    margin-right: 10px;
    height: 1450px;
    background: rgba(0, 0, 0, 0.78);
    box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    -webkit-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    -moz-box-shadow: -2px 2px 26px 8px rgba(186, 151, 67, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    // margin-bottom: 0px;
  }
  .price-plans-card-checkbox {
    // border-bottom: 1px solid white;
    padding-bottom: 15px;
    margin-top: 20px;
    padding-top: 0px;
    //justify-content:space-between;
  }

  .price-plans-card-button {
    width: 140px;
    height: 75px;
    border: 1px solid #ba9743;
    background: none;
    border-radius: 10px;
    color: #fff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .dollar-sign {
    font-size: 20px;
    font-weight: 500px;
  }
  .price-plans-card-desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: rgb(255, 251, 251);
  }
  .price-plans-card-desc1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: rgb(255, 251, 251);
    padding-top: 20px;
  }
  #hide {
    display: none;
  }
  .price-plans-value {
    font-size: 40px;
  }
}
