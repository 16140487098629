.helpCenter-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    text-align: center;
}
.helpCenter-listing-main-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.helpCenter-listing-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    margin-top: 15px;
}

.helpCenter-listing-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    // color: aqua;
}




@media (min-width:320px) and (max-width:374px)
{
    .font-help-banner{
        font-size: 30px !important;
    }
    .helpCenter-title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        text-align: center;
    }
    .simple-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        text-align: justify;
        color: #000000;
    }
    .helpCenter-listing-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
    }
    .helpCenter-listing-title {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        margin-top: 15px;
    }
}



@media only screen and (min-width:375px) and (max-width:768px)and (max-width:767px)  {
    
    .helpCenter-title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        text-align: center;
    }
    .helpCenter-listing-title {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        margin-top: 15px;
    }
    .helpCenter-listing-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
    }
}

@media only screen and (min-width:768px) and (max-width:1023px) {
    .helpCenter-title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        text-align: center;
    }
    .helpCenter-listing-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
    }
    
}

@media only screen and (min-width:1024px) and (max-width:1439px) {
    .font-help-banner{
        font-size: 54px !important;
    }
   
    .helpCenter-title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 37px;
        text-align: center;
    }
    .helpCenter-listing-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
    }
    .helpCenter-listing-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
    }
    
}


@media only screen and (min-width:1440px) and (max-width:2559px) {
    .font-help-banner{
        font-size: 70px !important;
    }
    .helpCenter-title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        text-align: center;
    }
   
 
}

@media (min-width:2560px) {
    .font-help-banner{
        font-size: 37px !important;
    }
    .helpCenter-title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 37px !important;
        text-align: center;
    }
   
 
}



