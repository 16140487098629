.PricePlans-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.PricePlans-card {
  border-radius: 1.25rem !important;
  width: 100% !important;
  margin-top: 20px;
}

.PricePlans-desc {
  font-weight: 400;
  font-size: 5px;
  word-break: normal;
  // width: 732px;
}

.PricePlans-card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #030303;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.PricePlans-card-value {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
}

.PricePlans-evaluation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.PricePlans-card-ul {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  width: 300px;
}

.PricePlans-card-ul > li {
  margin-top: 10px;
  list-style: url("../../assets/images/price-plans/list-icon.png");
}

.PricePlans-input > input[type="radio"] {
  background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%) !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent !important;
  background-clip: text;
  font-family: "Poppins";
}

.PricePlans-card-ul-container {
  padding-right: 30px !important;
  align-content: center;
  align-items: center;
}

.PricePlans-button {
  width: 270px;
  height: 70px;
  border: none;
  border-radius: 38px;
  border: 1px solid #ba9743;
  background: none;
}

.PricePlans-card-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.PricePlans-card-corner-img {
  width: 100px;
  height: 50px;
  position: absolute;
  left: -4px;
}

.PricePlans-checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 320px) and (max-width: 374px) {
  h5 {
    font-size: 13px;
    font-weight: 600 !important;
  }
  .PricePlans-checkbox-container {
    justify-content: right;
    align-items: center;
  }
  .contain {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
  .PricePlans-desc {
    font-size: 9px;
    line-height: 24px;
    word-break: normal;
  }

  .PricePlans-card-value {
    font-size: 36px;
  }
  .PricePlans-card-ul > li {
    margin-top: 0px;
    font-size: 12px;
    list-style: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAhOAAAITgGMMQDsAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMJSURBVHgBvVRNaFNBEJ7Zty/9tUmhBz3YRs+CyUE8lNL0UOpBaNpTi0gTPIinevVUexCkIujBm7SKYE/yIohCEUz1IOghwYpFBNuiN9EEW9vYvOy4u2/fT9qcHUjevnkz38583+witDBnKZPo2ec5RBgnghQBJJSfMSwTQlnU6eHolZfFVrl40PFmaWxWAswBYS9pD+kw1CtSTzKhq1ablRu88HyrJWBJVlXjXY6MHyby/ObPAKB+JaJoftVCyp+9+KLgO5i/EB3xJZuzYc4tjNkW2ObHLQuVj3MG6hd8k2v5TDDOnffL5zNNFa49yc41BMzJF4zuj16fXtOqVwqrVl1giLIptiGdzheqfN3JJolZOUZhrEoPmRMyGRGZ17oGCsC9/qUrCQm8KpfXuRWzM6gcHj2oi0EVyDT/RAwNe6CAvcpV2ZpcU6RKxFkNKDmZkYGaewrUPNwZGl/85CTEepL4o3ybIKAE1abxbyvTUgPOU0EfxiJCYoRP6k5OYtexIfhZvkEyDyHKLaoOIC0rZHFVPoRtgNXWB50DWfjz9TEId1ejdhwfx86jQ1BZuwlYr6BSWlHjgXnQsr+4nAhuOINgbJEqZEnqek9fg51PC9DePwW8ux921heANSrAuA+GkTnXkIi/313akEdqQFaHmsWQa2xL5sCKS0bqv2Dv8y0Ad8+cmxAGMRBTQogJzm3+lGmFyCdE86+W4vsjgO0PIHa+AId9ANVmOCqIRkkjHcVErMQ72pkjGlpyo4IX5e8Ou2tqgBBsBhERmubc5K3iqXtb2lErXX4lxzeD5rsCZLqVJo4C+iOzYPZHdLExciR9v8jVp73a37zrUsm/pkJ6IlyF1fg0+scUpQZ3+wYfFE2cZxsrUzkQsEgh54bQiEr+9EdQpbN8Ymw5HbYesY9ONiUEOuooehcCGQXDWgMhQF+4d+og5tMThWpLQGVvl88lmctzAsWMTBzwRwL02BsiEYvyoMyfmX5WPJh/CDBqrxdHhxsupNwG9aqbU876JrPrhZF8sQr/y/4B85BHgIMFrgsAAAAASUVORK5CYII=);
  }
  .PricePlans-button {
    width: 220px;
    height: 50px;
    border: none;
    border-radius: 38px;
    border: 1px solid #ba9743;
    background: none;
  }
}


@media (min-width: 375px) and (max-width: 767px) {
  h5 {
    font-size: 17px;
    font-weight: 600 !important;
  }
  .PricePlans-checkbox-container {
    justify-content: right;
    align-items: center;
  }
  .contain {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
  .PricePlans-desc {
    font-size: 14px;
    line-height: 24px;
    word-break: normal;
  }

  .PricePlans-card-value {
    font-size: 36px;
  }
  .PricePlans-card-ul > li {
    margin-top: 0px;
    font-size: 12px;
    list-style: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAhOAAAITgGMMQDsAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMJSURBVHgBvVRNaFNBEJ7Zty/9tUmhBz3YRs+CyUE8lNL0UOpBaNpTi0gTPIinevVUexCkIujBm7SKYE/yIohCEUz1IOghwYpFBNuiN9EEW9vYvOy4u2/fT9qcHUjevnkz38583+witDBnKZPo2ec5RBgnghQBJJSfMSwTQlnU6eHolZfFVrl40PFmaWxWAswBYS9pD+kw1CtSTzKhq1ablRu88HyrJWBJVlXjXY6MHyby/ObPAKB+JaJoftVCyp+9+KLgO5i/EB3xJZuzYc4tjNkW2ObHLQuVj3MG6hd8k2v5TDDOnffL5zNNFa49yc41BMzJF4zuj16fXtOqVwqrVl1giLIptiGdzheqfN3JJolZOUZhrEoPmRMyGRGZ17oGCsC9/qUrCQm8KpfXuRWzM6gcHj2oi0EVyDT/RAwNe6CAvcpV2ZpcU6RKxFkNKDmZkYGaewrUPNwZGl/85CTEepL4o3ybIKAE1abxbyvTUgPOU0EfxiJCYoRP6k5OYtexIfhZvkEyDyHKLaoOIC0rZHFVPoRtgNXWB50DWfjz9TEId1ejdhwfx86jQ1BZuwlYr6BSWlHjgXnQsr+4nAhuOINgbJEqZEnqek9fg51PC9DePwW8ux921heANSrAuA+GkTnXkIi/313akEdqQFaHmsWQa2xL5sCKS0bqv2Dv8y0Ad8+cmxAGMRBTQogJzm3+lGmFyCdE86+W4vsjgO0PIHa+AId9ANVmOCqIRkkjHcVErMQ72pkjGlpyo4IX5e8Ou2tqgBBsBhERmubc5K3iqXtb2lErXX4lxzeD5rsCZLqVJo4C+iOzYPZHdLExciR9v8jVp73a37zrUsm/pkJ6IlyF1fg0+scUpQZ3+wYfFE2cZxsrUzkQsEgh54bQiEr+9EdQpbN8Ymw5HbYesY9ONiUEOuooehcCGQXDWgMhQF+4d+og5tMThWpLQGVvl88lmctzAsWMTBzwRwL02BsiEYvyoMyfmX5WPJh/CDBqrxdHhxsupNwG9aqbU876JrPrhZF8sQr/y/4B85BHgIMFrgsAAAAASUVORK5CYII=);
  }
  .PricePlans-button {
    width: 220px;
    height: 50px;
    border: none;
    border-radius: 38px;
    border: 1px solid #ba9743;
    background: none;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .PricePlans-desc {
    font-weight: 400;
    font-size: 18px;
    word-break: normal;
  }
  .font-h-one-price-plan-mobile {
    font-size: 32px !important;
  }
  .PricePlans-card-ul {
    font-size: 18px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .font-h-one-price-plan-mobile {
    font-size: 37px !important;
    font-weight: 600 !important;
    margin-top: 75px;
  }

  .contain {
    display: flex;
    margin-top: 40px;
  }
  .PricePlans-desc {
    font-weight: 400;
    font-size: 18px;
    word-break: normal;
    // width: 732px;
  }
}

@media (min-width: 1440px) and (max-width: 2560px) {
  .contain {
    display: flex;
    margin-top: 40px;
  }
  .font-h-one-price-plan-mobile {
    font-size: 50px !important;
    font-weight: 700 !important;
    margin-top: 75px;
    line-height: 60px;
  }
  .PricePlans-desc {
    font-weight: 400;
    font-size: 19px;
    word-break: normal;
  }
}

@media (min-width: 2560px) {
  .contain {
    display: flex;
    margin-top: 40px;
  }
  .font-h-one-price-plan-mobile {
    font-size: 37px !important;
    font-weight: 700 !important;
    margin-top: 75px;
    line-height: 60px;
  }
  .PricePlans-desc {
    font-weight: 400;
    font-size: 19px;
    word-break: normal;
  }
}
