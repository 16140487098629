.industry-cards {
  display: flex;
  flex-direction: column;
}
.industry-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 75px;
  text-align: center;
  background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.industry-para {
  width: 732px;
  margin: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
  color: #000000;
}
.active-items-cards {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #ba9743;
}
.total-items-cards {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}
.industry-cards-title {
  padding-top: 20px;
  text-align: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 24px;
  color: #ba9743;
}
.industry-cards-des {
  text-align: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #726d6d;
}
.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding-top: 60px !important;
}
.carousel-button-group {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 1px;
  right: 0px;
}
.react-multiple-carousel__arrow {
  display: none;
}
.industry-heading {
  font-size: 20px !important;
  line-height: 26px;
}

@media (min-width: 310px) and (max-width: 374px) {
  .carousel-button-group {
    margin-right: 20px;
  }
  .items {
    margin-left: 10px;
  }
  .industry-heading {
    font-size: 15px !important;
    line-height: 26px;
  }
  .industry-para {
    font-size: 10px;
    line-height: 24px;
    width: 210px !important;
  }
  .industry-cards-des {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 0px;
    color: #212529;
  }
  .industry-cards-title {
    padding-top: 20px;
    text-align: center;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    background: linear-gradient(180deg, #ffc107 0%, #ba9743 100%);
    -webkit-background-clip: text;

    -webkit-text-fill-color: transparent;
  }
}



@media (min-width: 375px) and (max-width: 767px) {
  .carousel-button-group {
    margin-right: 20px;
  }
  .items {
    margin-left: 10px;
  }
  .industry-heading {
    font-size: 20px !important;
    line-height: 26px;
  }
  .industry-para {
    font-size: 14px;
    line-height: 24px;
    margin-left: 10px;
    margin-right: 10px;
}
  .industry-cards-des {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 0px;
    color: #212529;
  }
  .industry-cards-title {
    padding-top: 20px;
    text-align: center;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    background: linear-gradient(180deg, #ffc107 0%, #ba9743 100%);
    -webkit-background-clip: text;

    -webkit-text-fill-color: transparent;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .items {
    margin-left: 100px;
    margin-right: 100px;
  }
  .industry-heading {
    font-size: 32px !important;
    line-height: 42px;
  }
  .industry-para {
    font-size: 18px;
    width: 400px !important;
  }
  .industry-cards-title {
    font-size: 18px;
  }
  .industry-cards-des {
    font-size: 18px;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .items {
    margin-left: 100px;
    margin-right: 100px;
  }
  .industry-heading {
    font-size: 37px !important;
    line-height: 48px;
  }
  .industry-para {
    font-size: 18px;
    width: 400px !important;
  }
  .industry-cards-title {
    font-size: 18px;
  }
  .industry-cards-des {
    font-size: 18px;
  }
  .industry-heading {
    margin-top: 130px;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .industry-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 50px !important;
    line-height: 60px;
    text-align: center;
    background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 130px;
    
  }
  .items {
    margin-left: 150px;
    margin-right: 150px;
  }
  
}

@media (min-width: 2560px) {
  .items {
    margin-left: 250px;
    margin-right: 250px;
  }
  .industry-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 37px !important;
    line-height: 75px;
    text-align: center;
    background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding-top: 60px !important;
  
}
}
