.ClientReview-min-container {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
        url("../../assets/images/client-review-banner.png");
    // width: 100%;
    background-size: 100% 100%;
    height: 593px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

// .slick-track{
//     width: 800px !important;
// }
.ClientReview-img-container {
    // width: 254px;
    // height: 246px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

@media (min-width:320px) and (max-width:424px)
{
    .ClientReview-min-container {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url(http://localhost:3000/static/media/client-review-banner.41f8973….png);
        background-size: 100% 100%;
        height: 495px;
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 18px;
    }
}


@media (min-width:768px) and (max-width:1023px){
    .font-client-review{
        font-size: 32px !important;
    }
}

@media (min-width:1024px) and (max-width:1439px){
    .font-client-review {
        font-size: 37px !important;
        font-weight: 600 !important;
    }
}
@media (min-width:1440px) and (max-width:2559px){
    .font-client-review {
        font-size: 50px !important;
        font-weight: 700 !important;
        line-height: 60px !important;
    }
}

@media (min-width:2560px) {
    .font-client-review {
        font-size: 37px !important;
        font-weight: 700 !important;
        line-height: 60px !important;
    }
}