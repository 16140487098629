#second-section {
  width: 100%;

  // background-color: #000;
  // background: url("../../assets/images/waves.png");
  background-size: cover;
  position: relative;
}
#second-section .container {
  padding-top: 20px;
}

.header-button {
  margin-top: 15px;
}



@media (max-width: 992px) {
  #second-section .container {
    padding-top: 22px;
  }
}

#second-section h1 {
  margin: 0;
  color: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 140%;

  background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

#second-section h2 {
  color: #000;
  margin: 0px 0 0 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  letter-spacing: -0.01em;

}

#second-section .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 50px;
  transition: 0.5s;
  margin-top: 30px;
  border: 2px solid #fff;
  color: #fff;
}

#second-section .btn-get-started:hover {
  background: #5fcf80;
  border: 2px solid #5fcf80;
}

@media (min-width: 310px) and (max-width: 374px) {

  #second-section h1 {
    font-size: 15px;
    margin-left: 18px;
    line-height: 25px;
}

.second-section-image {
  height: 145px;
  margin-right: 18px;
}
#second-section h2 {
  font-size: 10px !important;
  line-height: 24px;
  margin-left: 18px;
}

  
}




@media (min-width: 375px) and (max-width: 767px) {
  #second-section {
  }

  #second-section h1 {
    font-size: 20px;
    margin-left: 18px;
    line-height: 25px;
  }

  #second-section h2 {
    font-size: 12px !important;
    line-height: 24px;
    margin-left: 18px;
}

.second-section-image {
  height: 200px;
  margin-right: 20px;
}
}

@media (min-width: 768px) and (max-width: 1023px) {
  #second-section h1 {
    font-size: 32px;
    line-height: 34px;
    margin-left: 50px;
  }

  #second-section h2 {
    font-size: 18px;
    line-height: 24px;
    margin-left: 50px;
  }

  .second-section-image {
    height: 340px;
    margin-right: 50px;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {


  #second-section {
    margin-top: 110px;
  }

  #second-section h1 {
    font-size: 37px !important;
    line-height: 40px !important;
    margin-left: 0px !important;
  }

  #second-section h2 {
    font-size: 19px !important;
    line-height: 24px;
    margin-left: 0px !important;
  }

  .second-section-image {
    height: 360px !important;
    margin-right: 0px;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  #second-section {
    background-attachment: fixed;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
 
  #second-section  {
    font-size: 38px;
    line-height: 36px;
    margin-left: 20px;
    margin-top: 130px;
  }
  #second-section h1 {
    font-size: 38px;
    line-height: 36px;
    margin-left: 20px;
  }

  #second-section h2 {
    font-size: 18px;
    line-height: 24px;
    margin-left: 20px;
  }

  .second-section-image {
    height: 460px;
  }
}


@media (min-width:1440px) and (max-width:2559px){

  #second-section {
    margin-top: 110px;
  }

  #second-section h1 {
    font-size: 50px !important;
    line-height: 55px !important;
    margin-left: 0px !important;
    font-weight: 700 !important;
}

  #second-section h2 {
    font-size: 22px !important;
    line-height: 34px;
    margin-left: 0px !important;
  }

  .second-section-image {
    height: 460px !important;
    margin-right: 0px;
  }
 


}


@media  (min-width:2560px){
  #second-section h1 {
    margin: 0;
    color: #fff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 37px !important;
    line-height: 140%;
    background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .second-section-image {
    height: 460px !important;
    margin-right: 0px;
  }
 
  
}




