#hero {
  width: 100%;
  height: 80vh;
  // background-color: #000;
  background: url("../../assets/images/waves.png");
  background-size: cover;
  position: relative;
}
#hero .container {
  padding-top: 60px;
}
#hero h1 {
  margin: 0;
  color: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 140%;

  background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

#hero h2 {
  color: #eee;
  margin: 10px 0 0 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  letter-spacing: -0.01em;
  color: #ffffff;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 50px;
  transition: 0.5s;
  margin-top: 30px;
  border: 2px solid #fff;
  color: #fff;
}

#hero .btn-get-started:hover {
  background: #5fcf80;
  border: 2px solid #5fcf80;
}

.header-button {
  margin-top: 15px;
}

@media (min-width:310px) and (max-width: 374px) {
  #hero {
    height: 55vh;
  }

  #hero h1 {
    font-size: 15px;
    line-height: 26px;
    margin-left: 40px;
}

#hero h2 {
  font-size: 10px;
  line-height: 24px;
  margin-left: 42px;
}
  #hero img {
    height: 137px;
    margin-right: 32px;
}

  #hero .button{
    margin-left: 40px;
    width:130px !important;
    height: 25px;
    font-size: small;
    
  }
  
}


@media (min-width:375px) and (max-width: 767px) {
  #hero {
    height: 55vh;
  }

  #hero h1 {
    font-size: 20px;
    line-height: 26px;
    margin-left: 25px;
  }

  #hero h2 {
    font-size: 12px;
    line-height: 24px;
    margin-left: 25px;
  }
  #hero img{
    height:180px;
    margin-right: 20px;
  }

  #hero .button{
    margin-left: 40px;
    height: 30px;
    font-size: small;
    
  }
  
}





@media (min-width: 1440px) and (max-width:3000px) {
  #hero .container {
    padding-top: 62px;
  }
}


@media (min-width:1024px) and (max-width: 1439px) {
  #hero {
    height: 70vh;
  }

  #hero h1 {
    font-size: 38px;
    line-height: 36px;
    margin-left: 20px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-left: 20px;
  }
  #hero img{
    height:360px;
  }

  #hero .button{
    margin-left: 20px;
  }
  
}

@media (min-width:768px) and (max-width: 1023px) {
  #hero {
    height: 80vh;
  }

  #hero h1 {
    font-size: 32px;
    line-height: 33px;
    margin-left: 20px;
  }

  #hero h2 {
    font-size: 12px;
    line-height: 24px;
    margin-left: 20px;
  }
  #hero img{
    height:340px;
  }

  #hero .button{
    margin-left: 20px;
    height: 40px;
  }
  
}

@media (min-width:2560px)
{
  #hero h1 {
    margin: 0;
    color: #fff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 37px;
    line-height: 140%;
    background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
}




