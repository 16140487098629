.payment-banner-main-container {
    background: linear-gradient(0deg, #000000 0%, #201700 100%);
    box-shadow: 0px 6px 35px 6px rgba(186, 151, 67, 0.03);
    
    margin: auto;
    z-index: 8;
    position: relative;
    top: 0px;
    color: white;
}
.payment-banner-inner-container{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    padding: 10px 0px;
}


@media  (min-width:310px) and (max-width:374px) {
    .innerImg {
        height: 12px;
    }
     .payment-banner-main-container{
         width: 90%;
         margin-top: 20px;
     }
     .payment-banner-inner-container{
         width: 90%;
     }
   }

@media  (min-width:375px) and (max-width:767px) {
   .innerImg{
    height: 15px;
   }
    .payment-banner-main-container{
        width: 90%;
        margin-top: 20px;
    }
    .payment-banner-inner-container{
        width: 90%;
    }
  }

@media  (min-width: 768px) and (max-width:1023px) {
    .innerImg{
        height: 20px;
       }
    .payment-banner-main-container{
        width: 90%;
    }
  }

  @media  (min-width: 1024px) and (max-width:1439px) {
    
    .innerImg {
        height: 20px;
    }
    .payment-banner-main-container{
        width: 85%;
    }
  }

  @media  (min-width: 1440px) and (max-width:2559px){
    .innerImg{
        height: 25px;
       }
    .payment-banner-main-container{
        width: 85%;
    }
    
  }

  @media  (min-width: 2560px) and (max-width:3000px) {
    .innerImg{
        height: 30px;
       }
 
    .payment-banner-main-container{
        width: 50%;
    }
  }

 
 

 
  
  
  