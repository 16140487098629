#third-section {
  width: 100%;

  // background-color: #000;
  //   background: url("../../assets/images/waves.png");
  background-size: cover;
  position: relative;
}
#third-section .container {
  padding-top: 60px;
}

@media (max-width: 992px) {
  #third-section .container {
    padding-top: 62px;
  }
}

#third-section h1 {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 75px;

  background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

#third-section h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  /* or 170% */
  width: inherit;
  align-content: center;
  align-items: center;

  letter-spacing: -0.01em;

  color: #000000;
}

#third-section h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-left: 30px;
}

#third-section .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 50px;
  transition: 0.5s;
  margin-top: 30px;
  border: 2px solid #fff;
  color: #fff;
}

#third-section .btn-get-started:hover {
  background: #5fcf80;
  border: 2px solid #5fcf80;
}

@media (min-width: 1024px) {
  #third-section {
    background-attachment: fixed;
  }
}

@media (min-width: 320px) and (max-width: 374px) {
  #third-section {
    display: block;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  .abc {
    display: block;
  }
  .third-section-image {
    height: 170px;
  }

  h1 {
    font-size: 15px !important;

    font-weight: 600 !important;
  }
  #third-section h2 {
    font-size: 10px !important;
    line-height: 24px;
    margin-left: 15px;
  }
  .tick-image {
    height: 15px;
    padding-left: 0px;
    margin-left: 16px;
  }
  #third-section h3 {
    margin-left: 12px;
    font-size: 8px !important;
    margin-bottom: 0px !important;
  }


  #third-section h1 {
    font-size: 22px;
    margin-left: 15px;
    line-height: 26px;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  #third-section {
    display: block;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  .abc {
    display: block;
  }
  .third-section-image {
    height: 300px;
  }

  h1 {
    font-size: 20px !important;
    padding-left: 20px;
    font-weight: 600 !important;
  }
  h2 {
    padding-left: 20px;
    margin-right: 20px;
  }
  .tick-image {
    height: 21px;
    padding-left: 20px;
    margin-left: 19px;
  }
  #third-section h3 {
    margin-left: 13px;
    font-size: 10px !important;
  }
  #third-section h2 {
    font-size: 12px !important;
    line-height: 24px;
    margin-left: 15px;
  }

  #third-section h1 {
    font-size: 22px;
    margin-left: 15px;
    line-height: 26px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  #third-section {
    margin-top: 100px;
  }
  #third-section h1 {
    font-size: 32px;
    line-height: 36px;
    margin-left: 40px;
  }

  #third-section h2 {
    font-size: 18px;
    line-height: 24px;
    margin-left: 40px;
  }

  .tick-image {
    height: 21px;
    padding-left: 20px;
    margin-left: 19px;
  }

  #third-section h3 {
    margin-left: 16px;
    font-size: 14px;
  }

  .third-section-image {
    height: 340px;
  }
}

.header-button {
  margin-top: 15px;
}

@media (min-width: 1024px) and (max-width: 1439px) {
  #third-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }

  .abc {
    display: flex;
  }

  .third-section-image {
    height: 360px !important;

    margin-left: 130px;
  }

  #third-section h1 {
    font-size: 37px;
    line-height: 40px;
    margin-left: 0px;
  }

  #third-section h2 {
    font-size: 19px;
    line-height: 24px;
    margin-left: 0px;
    width: 480px !important;
  }
  #third-section h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-left: 18px;
  }

  .tick-image {
    height: 22px;
  }
  h3 {
    width: 560px;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  #third-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }

  #third-section h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    width: 550px;
    align-content: center;
    align-items: center;
    letter-spacing: -0.01em;
    color: #000000;
  }
  #third-section h1 {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .third-section-image {
    height: 435px !important;
    margin-left: 0px;
  }
  #third-section h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-left: 20px;
  }
  .abc {
    display: flex;
  }
  .tick-image {
    height: 30px;

    margin-top: 15px;
  }
  h3 {
    width: 560px;
    margin-top: 18px;
  }
}

@media (min-width: 2560px) {
  #third-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .abc {
    display: flex;
  }
  .tick-image {
    height: 30px;
    width: 30px;
  }
  .third-section-image {
    height: 435px !important;
    margin-left: 0px;
  }
  h3 {
    width: 560px;
  }
  #third-section h1 {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 37px !important;
    line-height: 75px;
    background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  #third-section h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    width: 430px !important;
    align-content: center;
    align-items: center;
    letter-spacing: -0.01em;
    color: #000000;
  }
  #third-section h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 17px !important;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-left: 30px;
  }
}
