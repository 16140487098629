

.faq-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #BA9743;
    width: 100% !important;
}

.faq-container {
    margin-top: 100px;
    margin-bottom: 100px;
}

.mui-accordian {
    border-top: 1px solid #FFD66B !important;
    // border-bottom: 1px solid #FFD66B !important
}

.Component-titlee-colors {

    background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
}

@media(min-width:320px)and (max-width:374px)
{
    .css-kx8j57-MuiTypography-root {
        margin: 0;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        width: 33%;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        font-size: 13px !important;
    }
}


@media(min-width:375px) and (max-width:767px)
{
    .Component-titlee-colors {
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
        background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

@media(min-width:768px) and (max-width:1023px)
{
    .Component-titlee-colors {
        font-size: 32px;
        font-weight: 700;
        line-height: 36px;
        background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

@media(min-width:1024px) and (max-width:1439px)
{
    .Component-titlee-colors {
        font-size: 37px;
        font-weight: 600;
        line-height: 45px;
        background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 60px !important;
    }
}


@media(min-width:1440px) and (max-width:2559px)
{
    .Component-titlee-colors {
        font-size: 50px;
        font-weight: 700;
        line-height:60px;
        background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 60px !important;
        margin-top: 40px !important;
    }
  
}

@media  (min-width:2560px) {
    .Component-titlee-colors {
        font-size: 37px !important;
        font-weight: 700;
        line-height: 60px;
        background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 60px !important;
        margin-top: 40px !important;
    }
    
}





