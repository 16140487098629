.intro-section-container {
  max-width: 80%;
  margin: auto;
  margin-top: 2rem;
  position: relative;
}
.intro-img-container {
  height: 70% !important;
  // width: 50% !important;
}
.intro-section-image {
  max-width: 70% !important;
  max-height: 70% !important;
}

.intro-btn {
  color: black !important;
  border-color: #ffffff !important;
  font-family: "Poppins";
  border-radius: 0px;
  font-size: 18px;
  font-weight: bold !important;
  transition: transform 0.3s ease-in-out !important;
}
.intro-section-description {
  text-align: justify;
}
.about-us-btn:hover {
  background-color: #1480f3 !important;
  color: #ffffff;
}
.flex {
  display: flex;
  flex-direction: column;
  & > div,
  & > ul {
    flex: 1;
  }
}
