.AdvanceFeature-ul>li {
    margin-top: 10px;
    list-style: url("../../assets/images/price-plans/list-icon.png");
}
.AdvanceFeature-list-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.AdvanceFeature-main-container{
    margin-top: 100px;
    margin-bottom: 50px;
}

@media (min-width:320px) and (max-width:374px){
    .advance-features-p{
        font-size: 15px !important;
    }
    .AdvanceFeature-ul {
        font-size: 10px;
    }
}
@media only screen and (min-width:375px) and (max-width:768px)and (max-width:767px)  {
    .AdvanceFeature-ul {
        font-size: 13px;
    }
}


@media (min-width:768px) and (max-width:1023px)
{
    .advance-features-p{
        font-size: 32px !important;
    }
}

@media (min-width:1024px) and (max-width:1439px)
{
    .advance-features-p{
        font-size: 37px !important;
        margin-top: 140px;
        font-weight: 600 !important;
    }
    .price-plan-title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 37px !important;
        text-align: center;
    }
}

@media (min-width:1440px) and (max-width:2559px)
{
    .advance-features-p{
        font-size: 50px !important;
        margin-top: 140px;
        font-weight: 700 !important;
        line-height: 60px;
    }
    .price-plan-title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 50px !important;
        text-align: center;
        line-height: 60px;
    }
    .AdvanceFeature-ul {
        font-size: 20px;
    }

}


@media (min-width:2560px)
{
    .advance-features-p {
        font-size: 37px !important;
        margin-top: 140px;
        font-weight: 700 !important;
        line-height: 60px;
    }
}
