.PowerMeet-main-container {
    margin-top: 100px;
    margin-bottom: 100px;
}

.PowerMeet-card-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
}

.PowerMeet-card {
    border-radius: 1.25rem !important;
    width: 328px !important;
    // height: 310px;
}

.PowerMeet-card-title {
    font-weight: 500 !important;
    font-size: 24px ;
}

.PowerMeet-card-desc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.PowerMeet-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

}
.PowerMeet-title{
    width: auto;
    font-size: 37px;
}

@media  (min-width: 310px) and (max-width:374px)  {
    
    .PowerMeet-card.card {
        /* margin-left: 45px; */
        margin-top: 20px;
        width: 200px!important;
    }
    
   
   
    .PowerMeet-title {
        width: auto;
        font-size: 15px;
    }
    .PowerMeet-card-title {
        font-size: 14px;
    }
    .PowerMeet-card-desc {
        font-size: 10px !important;
    }
    .PowerMeet-card.card {
        margin-top: 20px;
        width: 200px !important;
    }
    .PowerMeet-main-container {
        margin-top: 40px;
        margin-bottom: 100px;
    }
    .PowerMeet-card-desc {
        font-size: 10px !important;
    }
    .PowerMeet-card-container {
        align-items: center;
        display: flex!important;
        flex-direction: column;
        /* justify-content: space-around; */
        margin-top: 50px;
        flex-direction: column;
    }
    
  }



@media  (min-width: 375px) and (max-width:767px)  {
    
    .PowerMeet-card-container {
        display: block;
    }
    .PowerMeet-card.card{
        margin-top: 20px;
        margin-left: 20px;
    }

   
    .PowerMeet-title{
        font-size: 22px;
    }
    .PowerMeet-card-title{
        font-size: 20px;
    }
    .PowerMeet-card-desc {
        font-size: 12px !important;
    }
    
  }


  @media  (min-width: 768px) and (max-width:1023px) {
    
    .PowerMeet-card {
        border-radius: 1.25rem !important;
        width: 200px !important;
        // height: 310px;
    }
    .PowerMeet-title {
        width: auto;
        font-size: 32px;
    }
  }



@media  (min-width: 1024px) and (max-width:1439px) {
    
    .PowerMeet-card {
        border-radius: 1.25rem !important;
        width: 260px !important;
        // height: 310px;
    }
    .PowerMeet-card-desc {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
    }
  }

  @media  (min-width: 1440px) and (max-width:2559px) {
    
    .PowerMeet-title {
        width: auto;
        font-size: 50px;
    }
    .PowerMeet-card {
        border-radius: 1.25rem !important;
        
        width: 328px;
    }
   
    .PowerMeet-card-desc {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
    }

  }

  
  @media  (min-width: 2560px) {
    
    .PowerMeet-card {
        border-radius: 1.25rem !important;
        width: 328px;
    }
  }



  