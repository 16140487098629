.footer-section-container {
  max-width: 80%;
  margin: auto;
  margin-top: 4rem;
}

.row {
  --bs-gutter-x: 0rem;
}

.footer-logo-img {
  width: 281px;
  height: 66px;
}

.main-footer-container {
  background: #000;
}

.social-circle-icons li a:hover i,
.triggeredHover {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.text-small {
  font-size: 0.9rem;
}

a {
  color: #fff;
  text-decoration: none;
  transition: all 0.3s;
}

a:hover {
  text-decoration: none;
}

.small-font {
  font-size: 0.88rem;
}

.large-font {
  font-size: 1.6rem;
}

.form-control {
  background: #ffffff;
  border-color: #545454;
}

.form-control:focus {
  background: #212529;
}

.secondary-footer {
  background: #1480f3;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.icons {
  justify-content: center;
  align-items: center;
}

.silicon-nexus-footer-message {
  font-family: "Poppins" !important;
  font-size: 15px;
  text-justify: auto;
  text-align: justify;
}

.footer-text-heading {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  background: -webkit-linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hr-line-footer-heading {
  width: 60px;
  height: 2px;
  opacity: 1;
  margin-top: -10px;
  background-color: white;
}

.footer-item-text {
  font-family: "Poppins";
  font-size: 15px;
  text-justify: auto;
  text-align: justify;
}

.subscribe-btn {
  background: #1480f3 0% 0% no-repeat padding-box;
  border: 2px solid #1480f3;
  opacity: 1;
  color: #ffffff;
  font-weight: 600;
}

.subscribe-field {
  background: #fefefe 0% 0% no-repeat;
  border: 2px solid #fefefe;
  opacity: 1;
}

.subscribe-field:focus {
  background: #fefefe 0% 0% no-repeat;
  border: 2px solid #fefefe;
  opacity: 1;
  box-shadow: none;
}

.footer-item-text li {
  padding-top: 0.5rem;
}

.underline-text {
  text-decoration: none;
}

.footer-nav-link {
  font-family: "Poppins";
  font-size: 16px;
  margin-top: 5px !important;
  letter-spacing: 0px;
  color: #fefefe;
  opacity: 1;
  // transition: font-weight 0.25s;
}

.footer-nav-link:hover {
  font-weight: 600;
  color: #1480f3;
}

.social-icon {
  cursor: pointer;
  background: linear-gradient(180deg, #ba9743 0%, #ffd66b 100%);
  transition: transform 0.3s ease-in-out;
  color: #000;
  height: 45px;
  width: 45px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.social-icon:hover {
  transform: translateY(-2px);
}

.social-icon:nth-child(1) i:hover {
  color: #000;
}

.social-icon:nth-child(2) i:hover {
  color: #000;
}

.social-icon:nth-child(4) i:hover {
  color: #000;
}

.social-icon:nth-child(3) i:hover {
  color: #000;
}

.copy-right-text {
  font-family: "Poppins";
  color: #ffffff;
}

.footer-textField-grid .MuiTextField-root {
  margin-top: 30px;
  border-color: #BA9743 !important;
  border: 1px solid #BA9743 !important;
  border-radius: 35px !important;
  // color: white !important;
}
.footer-textField-grid .css-152mnda-MuiInputBase-input-MuiOutlinedInput-input{
  color: rgb(255, 255, 255) !important;
}

.google-play-image{
  height: 27px;
}

.apple-app-store-image{
  height: 29px;
}